@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.containerAcademy {
  width: 85%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerAcademy .containerHeaderAcademy {
  display: flex;
  width: Fill (330px)px;
  height: Hug (145px)px;
  padding: 20px;
  gap: 10px;
  border-radius: 12px;
  background-color: #D8F6F5;
  position: relative;
}

.containerAcademy .containerHeaderAcademy .containerLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerAcademy .containerHeaderAcademy .containerLeft .title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.containerAcademy .containerHeaderAcademy .containerLeft .title .text1 {
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #211E1F;
}

.containerAcademy .containerHeaderAcademy .containerLeft .title .text2 {
  width: Hug (32px)px;
  height: Hug (13px)px;
  padding: 2px 6px 2px 6px;
  gap: 10px;
  border-radius: 100px;
  border: 1.2px solid #0AC6BD;
  background-color: #FFFFFF;
  font-family: IBM Plex Sans;
  font-size: 7px;
  font-weight: 700;
  line-height: 9.1px;
  letter-spacing: 0.1em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0AC6BD;
}

.containerAcademy .containerHeaderAcademy .containerLeft .subtitle {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #211E1F;
}


.containerAcademy .containerHeaderAcademy .containerRigth {
  flex: 0.6;

}

.containerAcademy .containerHeaderAcademy .containerRigth img {
  width: 110px;
  height: 118px;
  position: absolute;
  top: -10px;
}

.containerAcademy .menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.containerAcademy .menu-item {
  flex: 1;
  height: 50px;
  border-radius: 12px;
  border: 1px solid #E3E3E3;
  background-color: #F7F7F7;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #211E1F;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAcademy .active {
  border: 1px solid #0AC6BD;
  background-color: #D8F6F5;
  font-weight: 700;
}

.containerAcademy .alertNotVideos {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 0%;
  color: #2D2D2D;
  margin: 10px;
}