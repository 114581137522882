.containerListItens {
  display: flex;
  gap: 10px;
  padding: 24px;
  justify-content: center;
  flex-wrap: wrap;
}

.containerListItens .itemList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 75px;
  padding: 12px;
  gap: 2px;
  border-radius: 10px;
  background: #F7F7F7;
  border: 1px solid #F7F7F7;
  margin: 0px;
}

.containerListItens .itemList .containerIMgMenus {
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.containerListItens .itemList .textMenu {
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: #2D2D2D;
}

.containerListItens .itemList img {
  /* width: 35px !important;
  height: 35px; */
}

.containerListItens .breve {
  width: 51px;
height: 13px;
border-radius: 100px;
border: 1.2px solid #2D2D2D;
background: #F7F7F7;
font-family: IBM Plex Sans;
font-size: 7px;
font-weight: 700;
line-height: 9.1px;
letter-spacing: 0.1em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #EC5569;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 33px;
}