@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes loadingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  animation: loadingAnimation 1s infinite;
}

.containerLogin {
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0px;
  right: 0px;
}

.containerContentALl {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerLogin .logo {
  /* position: absolute; */
  width: 133px;
  /* top: 40px;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.containerLogin .lineLOgin {
  /* position: absolute; */
  width: 90vw;
  height: 1px;
  background-color: #F6F2F3;
  /* top: 72px;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.containerLogin .iconlogin1 {
  width: 270px;

}

.containerLogin .iconlogin2 {
  width: 150px;
  /* margin-bottom: 75px; */
}

.containerLogin .imgBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -5;
  /* object-fit: contain; */
}

.contentCONtainerLogin {
  height: 100%;
  background-image: url(../../assets//Frame\ 427320198.svg);
  background-size: cover;
  position: relative;
  display: flex;
}

.containerLogin .contentContainer {
  /* position: relative;
  top: -10%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.containerLogin .contentContainer .title {
  font-family: IBM Plex Sans;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  color: #FFFFFF;
  width: 75%;
}

.containerLogin .contentContainer .subtitle {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.2px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
  width: 90%;
}

.containerLogin .contentContainer .contianerButtonsLogin {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.containerLogin .contentContainer .buttonLogin {
  width: 90vw;
  height: 43px;
  border-radius: 100px;
  padding: 12px, 16px, 12px, 16px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
}

.containerLogin .contentContainer .style1 {
  border: 1px solid #0AC6BD;
  background-color: #0AC6BD;
}

.containerLogin .contentContainer .style2 {
  width: 90vw;
  height: 43px;
  gap: 8px;
  border-radius: 80px;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  color: #FFFFFF;
  border: 0.8px solid var(--White, #FFFFFF);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.containerLogin .contentContainer2 {
  /* position: relative;
  top: -10%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.containerLogin .contentContainer2 .title {
  font-family: Barlow Semi Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  color: #FFFFFF;
  width: 75%;
}

.containerLogin .contentContainer2 .subtitle {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.2px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
  width: 90%;
}

.containerLogin .contentContainer2 .contianerButtonsLogin {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.containerLogin .contentContainer2 .buttonLogin {
  width: 80vw;
  height: 43px;
  border-radius: 100px;
  padding: 12px, 16px, 12px, 16px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
}

.containerLogin .contentContainer2 .style1 {
  border: 1px solid #0AC6BD;
  background-color: #0AC6BD;
}

.containerLogin .contentContainer2 .style2 {
  width: 149px;
  height: 32px;
  gap: 8px;
  border-radius: 80px;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  color: #FFFFFF;
  border: 0.8px solid var(--White, #FFFFFF);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}


.containerLogin .containerInput {
  width: 80vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.containerLogin .containerInput .text {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 5px;
  text-align: left;
  color: #FFFFFF;
}

.containerLogin .containerInput .inputLogin {
  /* width: 76vw; */
  height: 35px;
  border-radius: 5px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  outline: none;
  /* padding: 5px; */
}

.footerLOgin {
  display: flex;
  width: 80vw;
  height: 25px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  color: #fff;
  margin: auto;
  border-top: 1px solid #FFFFFF0D;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0px;
}

.footerLOgin img {
  width: 14px;
  height: 11.99px;
}

.termosBox {
  width: 80vw;
  height: 52px;
  gap: 6px;
  border-radius: 3px;
  background-color: #000000;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin-top: 10px;
}

.termosBox a {
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;

}

.SMsbUtton {
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-underline-position: from-font;
  color: #fff;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.timer {
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-underline-position: from-font;
  color: #fff;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: 0px
}

@media only screen and (max-width: 649px) {
  .containerLogin .containerInput .inputLogin {
    width: 76vw;
  }

  .contentContainerWeb {
    display: none;
  }

  .footerAViso {
    display: none;
  }
}

@media only screen and (min-width: 650px) {
  .containerLogin  {
    display: none;
  }

  
  .footerLOgin {
    display: none;
  }

  .contentCONtainerLogin {
    background-image: url(../../assets//Frame\ 427320264.svg);
    background-size: auto;
  }

  .containerLogin .contentContainer {
    display: none;
  }

  .containerLogin .logo {
    display: none;
  }

  .containerLogin .contentContainer .style2 {

    color: #11192D;
    border: 0.8px solid var(--White, #11192D);
  }

  .containerLogin .contentContainer .title {
    color: #11192D !important;
  }

  .containerLogin .contentContainer .subtitle {
    color: #11192D !important;
  }

  .containerLogin .contentContainer2 {
    width: 424px;
    height: 424px;
    /* padding: 60px; */
    gap: 24px;
    border-radius: 20px;
    background: #FFFFFF;
    border: 1px solid #57BAB8;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/Clip\ path\ group.svg);
  }

  .containerLogin .contentContainer2 .style2 {

    color: #11192D;
    border: 0.8px solid var(--White, #11192D);
  }

  .containerLogin .contentContainer2 .title {
    color: #11192D !important;
  }

  .containerLogin .contentContainer2 .subtitle {
    color: #11192D !important;
  }

  .containerLogin .containerInput .text {
    color: #11192D !important;
  }

  .containerLogin .backGrounDivid {
    height: 50% !important;

  }

  .containerLogin .buttonLogin {
    width: 304px !important;
  }

  .containerLogin .contentContainer .style2 {
    width: 304px !important;
  }

  .containerLogin .lineLOgin {
    width: 304px !important;
    display: none;
  }

  .termosBox {
    width: 280px !important;
    padding: 0px 10px;
  }

  .containerLogin .containerInput {
    width: 304px !important;
  }

  .containerLogin .contentContainer2 .buttonLogin {
    margin-top: 0px;
  }

  .containerLogin .containerInput {
    margin-top: 0px;
  }

  .contentContainer2 .title {
    margin-top: 0px !important;
  }

  .SMsbUtton {
    color: #11192D;
  }

  .timer {
    color: #11192D;
  }
}

@media only screen and (max-height: 958px) {
  .footerAViso {
    display: none;
    /* background-color: #0AC6BD; */
  }
}