.carousel-container {
    flex: 1;
    height: 147px;
    border-radius: 20px;
    gap: 13.2px;
    padding: 38px;
    background: #F7F7F7;
  }

  .carousel-container .text{
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: 0%;
    text-align: center;
    color: #091023;
    margin-bottom: 10px;
  }

  .carousel-wrapper {
    position: relative;
    overflow: hidden;
  }

  .carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-slide {
    flex: 0 0 calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .carousel-slide img {
    max-height: 48px;
    width: auto;
    object-fit: contain;
  }

  .carousel-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }

  .carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .carousel-dot.active {
    background-color: #2563eb;
  }