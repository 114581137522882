.titleSKillsCard {

  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #211E1F;

}

.subTitleSKillsCard {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  color: #211E1F;
  margin-top: 10px;
}

.textSkil {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: center;
  color: #211E1F;
}

.titlePageSKils {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #211E1F;

}

.buttonSaveSKils {
  /* height: Hug (45px)px; */
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 100px;

  background: #FFFFFF;
  border: 1px solid #EC5569;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: left;
  color: #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.iconNotific {
  width: 10px;
height: 10px;
background: #EC5569;
border-radius: 10px;
position: absolute;
top: 5px;
right: 5px;
}