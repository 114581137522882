.buttonNotifications {
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 80px;
  background: #FFFFFF;
  border: 0.8px solid #0AC6BD;
  cursor: pointer;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: #0AC6BD;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleNotifications {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height:18.2px;
  color: #2D2D2D;
  text-align: center;
}

.subTitleotifications {
  font-family: Barlow Semi Condensed;
  font-size: 12px;
  font-weight: 300;
  line-height: 15.6px;
  text-align: left;
  color: #818181;
}

.titleNotificationsNOt {
  font-family: IBM Plex Sans;
  font-size: 17px;
  font-weight: 500;
  line-height:18.2px;
  text-align: center;
  color: #2D2D2D;
  margin-top: 10px;
}

.containerBoxALl {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 15px; */
  flex: 1;
}