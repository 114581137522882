.titleFaq {
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #211E1F;
  margin-bottom: 10px;
}

.subtitleFaq {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.8px;
  color: #211E1F;
  text-align: left;
}

.conatinerInbox .cardBox {
  display: flex;
  padding: 20px;
  border-radius: 12px;
  gap: 20px;
  background: var(--White, #FFFFFF);
  border: 1px solid var(--fake-white, #E3E3E3)
}

.conatinerInbox .notChecked {
  border: 1px solid #EC5569;
  position: relative;
}

.conatinerInbox .cicleNoti {
  display: none;
}

.conatinerInbox .notChecked .cicleNoti {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #EC5569;

}


.conatinerInbox .cardBox img {
  width: 47px;
  height: 47px;
}

.conatinerInbox .cardBox .containerText {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.conatinerInbox .cardBox .containerText .headerCardInbox .title {
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  color: #211E1F;
}

.conatinerInbox .cardBox .containerText .headerCardInbox .text {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #818181;
}

.conatinerInbox .cardBox .containerText .text {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  color: #2D2D2D;
}

.conatinerInbox .cardBox .containerText .text2 {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 16px;
  color: #46817F;
}