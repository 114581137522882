:root {
    --toastify-color-light: #2D2D2D;
    --toastify-color-dark: #fff;
    --toastify-color-info: #3498db;
    --toastify-color-success: #57BAB8;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #EC5569;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
  
    --toastify-text-color-light: #FFFFFF;
    --toastify-text-color-dark: #fff;
  
  
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
  
    --toastify-spinner-color: "#EC5569";
    --toastify-spinner-color-empty-area: #e0e0e0;
 
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  
    --toastify-color-progress-bgo: .2;
  }

  .Toastify__close-button {
    color: white;
    opacity: 1;
}