.containerProfileTalents {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.containerProfileTalents .containerHeaderProfile {
  height: 220px;
  position: relative;
  background: #FFFFFF;
  z-index: 0;
}

.containerPhotoProfile .fotoIcon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.containerProfileTalents .cardCompleteProfile {
  width: 80vw;
  /* height: 180px; */
  padding: 18px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background: #2D2D2D;
  display: flex;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%);
  font-family: IBM Plex Sans;
}

.containerProfileTalents .cardCompleteProfile .cardLeft .textProfile {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.containerProfileTalents .cardCompleteProfile .cardLeft .textProfileSUb {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  color: #FFFFFF;
  margin-left: 10px;
}

.containerProfileTalents .cardCompleteProfile .cardLeft .button {
  width: 100%;
  height: 32px;
  border-radius: 80px;
  background: #FFFFFF;
  border: 0.8px solid #2D2D2D;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  color: #2D2D2D;
  display: flex;
  align-items: center;
  justify-content: center;
}


.containerProfileTalents .cardCompleteProfile .cardLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.containerProfileTalents .cardCompleteProfile .cardRigth {
  flex: 0.6;
}


.containerProfileTalents .containerContentProfile {
  background: #2D2D2D;
  background-image: url(../../assets//Frame\ 427320198\ \(1\).svg);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerProfileTalents .containerContentProfile .containerPhotoProfile {
  margin-bottom: 10px;
  margin-top: 60px;
}

.containerProfileTalents .containerContentProfile .containerPhotoProfile img {
  width: 100px;
}

.containerProfileTalents .containerContentProfile .buttonClientProfile {
  width: 90vw;
  height: 45px;
  border-radius: 100px;
  /* padding: 12px, 16px, 12px, 16px; */
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background-color: #EC5569;
}

.containerProfileTalents .containerContentProfile .buttonSiginOut {
  width: 85vw;
  height: 43px;
  border-radius: 800px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  color: #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  cursor: pointer;
  border: 1px solid #EC5569;
  background: #2D2D2D;
}

.containerProfileTalents .containerContentProfile .containerInput {
  width: 89vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerProfileTalents .containerContentProfile .containerInput .inputLogin {
  height: 43px;
  border-radius: 5px;
  border: none;
  padding: 0px 10px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #211E1F;
}

.containerProfileTalents .containerContentProfile .containerInput .inputLogin::placeholder {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  color: #BFB7B8;
}

.containerProfileTalents .containerContentProfile .containerInput .inputLogin:focus {
  outline: none;
}

.containerProfileTalents .containerContentProfile .containerInput .text {
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: #FFFFFF;
}

.containerProfileTalents .contentContainer2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #F7F7F7;
  border-radius: 3px;
  /* width: 90vw; */
  gap: 30px;
  padding: 24px;
}

.containerProfileTalents .contentContainer2 .cardCheck {
  width: 70vw;
  padding: 18px 10px 18px 10px;
  gap: 8px;
  border-radius: 10px;
  border: 1px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  background-color: #F6F2F3;
  border: 1px solid var(--fake-white, #E3E3E3)
}

.containerProfileTalents .contentContainer2 .cardCheck .textCard {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #211E1F;
}

.fotterCheck .footerPageMObileProfile {
      color: #2D2D2D !important;
    border-top: 1px solid #0000000D !important;
    background-color: #fff !important;
}

.optionCustom {
  padding: 10px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #2D2D2D !important;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.optionCustom:hover {
  background: #f0f0f0;
}

@media only screen and (min-width: 800px) {
  .containerProfileTalents {
    width: 100%; /* Subtrai o padding total (18px + 18px) */
  }

  .containerProfileTalents .cardCompleteProfile {
    width: 80%; /* Subtrai o padding total (18px + 18px) */
  }

  .containerProfileTalents .containerContentProfile .buttonClientProfile {
    width: 80% !important; /* Subtrai o padding total (18px + 18px) */
  }

  .containerProfileTalents .containerContentProfile .containerInput {
    width: 80%; /* Subtrai o padding total (18px + 18px) */
  }

  .containerProfileTalents .contentContainer2 .cardCheck {
    width: 80%; /* Subtrai o padding total (18px + 18px) */
  }

  .containerProfileTalents .containerContentProfile .buttonSiginOut {
    width: 80% !important;
  }
}
