.cardSKillProfile {
    border-radius: 12px;
    gap: 20px;
    border-width: 1px;
    padding: 20px;
    background: var(--White, #FFFFFF);
    border: 1px solid var(--fake-white, #E3E3E3);
    display: flex;
    align-items: center;
}

.cardSKillProfile .conteinerNivelProfile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.cardSKillProfile .conteinerNivelProfile .text {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    color: #2D2D2D;
}

.textSLideHeaderProfile {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 20px;
    color: #211E1F;
}