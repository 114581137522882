.containerCardsSLides {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.containerCardsSLides .textSLideHeader {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.containerCardsSLides .conatinerLogoFilters {
    width: 80px;
    height: 40px;
    border-radius: 8px;
    gap: 8.64px;
    border-width: 1px;
    background: #FFFFFF;
    border: 1px solid var(--fake-white, #E3E3E3);
    display: flex;
    align-items: center;
    justify-content: center;
}