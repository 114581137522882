/* App.css */

  .header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 75px;
    background-color: #ffff;
    box-shadow: 0px 0px 0px 0px;
    color: white;
    text-align: center;
    transition: top 0.3s ease;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #F6F2F3;
  }

  .header .containerItensTop {
    width: 90%; 
    display: flex;
    align-items: center;
    justify-content: space-between;
 
  }

  .containerMenu {
    display: flex;
    width: 100vw;
}
  
  
  .header.hidden {
    top: -75px; /* Esconde a barra de navegação quando não visível */
  }
  
  .header.visible {
    top: 0; /* Exibe a barra de navegação */
  }
  
  main {
    margin-top: 80px;
    padding: 20px;
  }
  