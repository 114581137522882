.fit-cultural-container {
  width: 350px;
  height: 266px;
  border-radius: 20px;
  gap: 10px;
  border-width: 1px;
  /* padding: 18px; */
  background: #F7F7F7;
  border: 1px solid var(--fake-white, #E3E3E3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fit-cultural-container .titleFitCulutal {
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  color: #211E1F;

}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.chart-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.label {
  position: absolute;
  border-radius: 4px;
  padding-top: 2px;
  padding-right: 6px;
  padding-bottom: 2px;
  padding-left: 6px;
  background: #FFFFFF;

}

.label-content {
  white-space: nowrap;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #2D2D2D;

}

.idealizador {
  top: 18%;
  right: 0%;
}

.comunicador {
  top: 18%;
  left: 0%;
}

.analitico {
  bottom: 18%;
  left: 0%;
}

.executor {
  bottom: 25%;
  right: 0%;
}