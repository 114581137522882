.talent-card {
  width: 230px;
  min-height: 243px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  background: var(--White, #FFFFFF);
  border: 1px solid var(--fake-white, #E3E3E3)
}

.talent-card .headerPainelCard {
  display: flex;
  gap: 8px;
  align-items: center;
}

.talent-card .headerPainelCard .img {
  width: 42px;
  height: 42px;
  padding: 5px;
  border-radius: 50%;
  background-color: #E3E3E3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.talent-card .headerPainelCard img {
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.talent-card .headerPainelCard .talent-name {
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}

.talent-card .headerPainelCard .textSecondary {
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 300;
  color: #000000;
}

.boxInfoTalent {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 10px;
  padding: 10px;
  border-width: 1px;
  border: 1px solid #E3E3E3;
}

.boxInfoTalent .containerInfoBox {
  display: flex;
  align-items: center;
  gap: 6px;
}

.boxInfoTalent .containerInfoBox .text {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 13px;
  color: #000000;
}

.talent-card .viewTalentButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: auto;
}

.talent-card .viewTalentButton button {
  background: #EC5569;
  border: 1px solid #EC5569;
  height: 40px;
  flex: 1;
  padding: 0px 16px;
  gap: 10px;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.containerCulturalFit {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.containerCulturalFit .cardProfile {
  border-radius: 12px;
  padding-top: 6px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  gap: 5px;
  background: #D8F6F5;
  border: 1px solid #57BAB8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCulturalFit .cardProfile .text {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 12px;
  color: #2D2D2D;
}
