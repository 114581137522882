.containerBoxCompanies {
    background-image: url(../../assets//fundo.png);
    background-size: cover;
    position: relative;
    height: 100vh;
    width: 100vw;
}

.containerBoxCompanies .footerCOmpanies {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 170px;
    right: 50px;
    bottom: 10px;
    z-index: 100000;
    height: 30px;
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 12px;
    line-height: 16.8px;
    letter-spacing: 0%;
    color: #2D2D2D;
    gap: 8px;
}

.containerBoxCompanies .footerCOmpanies img {
    width: 14px;
    height: 11.989361763000488px;
}

.containerBoxCompanies .containerTopCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aqua;
    position: fixed;
    top: 110px;
    left: 145px;
    bottom: 110px;
    border-radius: 20px;
    border-bottom-width: 5px;

}

.containerScaleCompanies {
    width: 100vw;
    height: 100vh;
    transform: none;
}


@media only screen and (max-height: 772px) {
    .containerScaleCompanies {
        transform: scale(0.9);
        transform-origin: center;
    }
}

@media only screen and (max-height: 678px) {
    .containerScaleCompanies {
        transform: scale(0.8);
        transform-origin: center;
    }
}

@media only screen and (max-height: 580px) {
    .containerScaleCompanies {
        transform: scale(0.7);
        transform-origin: center;
    }
}

@media only screen and (max-height: 489px) {
    .containerScaleCompanies {
        transform: scale(0.6);
        transform-origin: center;
    }
}