@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.containerPodcasts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerPodcasts .card {
  display: flex;
  width: Fill (330px)px;
  height: Hug (130px)px;
  padding: 20px;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid #E3E3E3;
  background-color: #F7F7F7;
  position: relative;
  align-items: center;
}

.containerPodcasts .card_checked {
  border: 1px solid #0AC6BD;
  background-color: #D8F6F5;
}

.containerPodcasts .card .thumbnail {
  width: 130px;
  height: 90px;
  border-radius: 12px;
  background-color: #ABABAB;
  border: 1px solid #818181;
  position: relative;
  z-index: 2;
}

.containerPodcasts .card .thumbnail .iconPLay {
  display: none;
}

.containerPodcasts .card .thumbnail .thumb {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.containerPodcasts .card .thumbnail_checked {
  background-color: #6CDDD7;
  border: 1px solid #0AC6BD;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerPodcasts .card .thumbnail_checked .iconPLay {
  display: flex;
  width: 32px;
  height: 32px;
}


.containerPodcasts .card .containerText {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 6px;
  flex: 1;
}

.containerPodcasts .card .containerText .title {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2D2D2D;
}

.containerPodcasts .card .containerText .subtitle {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2D2D2D;
  display: flex;
  gap: 5px;
  align-items: center;
}

.containerPodcasts .card .containerText .subtitle img {
  width: 16px;
  height: 16px;
}

.containerPodcasts .card .iconNotifications {
  width: 10px;
  height: 10px;
  background-color: #EC5569;
  border-radius: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}