@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.chat-container {
  width: 960px;
  height: 600px;
  padding: 60px;
  gap: 18px;
  border-radius: 20px;
  border: 1px;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 2px 10px 0px #0000001A;
  /* order: 1;
  position: absolute; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chat-box {
  overflow-y: auto;
  margin-bottom: 20px;
  height: 520px;
  padding: 40px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-image: linear-gradient(rgba(249, 249, 249, 0.8), rgba(249, 249, 249, 0.8)), url(../../assets/bg-cinza.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  z-index: 2;
}

.chat-box div div {
  font-family: IBM Plex Sans;
}


.chat-box::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.message .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff7070;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.message .text {
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 250px;
}

.message.bot .text {
  background-color: #ffecec;
  color: #000;
}

.message.user .text {
  background-color: #d0f4f9;
  color: #000;
  margin-left: auto;
}

.chat-input {
  display: flex;
}

.chat-input .styleInput {
  flex-grow: 1;
  padding: 16px;
  gap: 10px;
  border-radius: 100px;
  border: 1px;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 16px;
  line-height: 20.8px;
  color: #818181;
}

.chat-input button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: Barlow Semi Condensed;
}


.containerButonsRegister {
  display: flex;
  flex: 1;
  gap: 24px;
}

.containerButonsRegister button {
  flex: 1;
  border-radius: 100px;
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  height: 43px;
}


.containerButonsRegister .button1 {
  background: #57BAB8;
}

.containerButonsRegister .button2 {
  background: #211E1F;

}

input[type="range"] {
  width: 100%;
}