.containerCarsSlides {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 18px;
}

.cardSlide {
    display: flex;
    align-items: center;
    width: 80vw;
    height: 81.7px;
    padding: 0px 18px;
    gap: 8px;
    border-radius: 10px;
    background: #F7F7F7;
    border: 1px solid var(--fake-white, #E3E3E3)
}

.cardSlide .boxIcon {
    width: 100px;
    height: 50px;
    border-radius: 5px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 6px;
}

.cardSlide .boxIcon img {
    /* width: 100%; */
    height: 100%;
    /* object-fit:cover; */
    /* object-position: center;  */
}



.cardSlide .boxTextSlide {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cardSlide .boxTextSlide .textCardSend {
    font-family: IBM Plex Sans;
    font-size: 13.3px;
    font-weight: 300;
    line-height: 17.29px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

@media only screen and (min-width: 800px) {
    .cardSlide {
      width: 90%;
    }
  }