.titleFaq {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #211E1F;
  margin-bottom: 10px;
}

.subtitleFaq {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  color: #211E1F;
  text-align: left;
}


.subtitleFaq3 {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.8px;
  color: #211E1F;
  text-align: left;
}

.subtitleFaq2 {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  color: #211E1F;
  text-align: left;
}

.cardWhatsapp {
  background-color: #2fd365;
  border-radius: 10px;
  border: none;
  display: flex;
  width: 100%;
  padding: 18px;
  color: #fafafa;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
}

