@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes loadingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  animation: loadingAnimation 1s infinite;
}

.containerSelfieLetter {
  font-family: IBM Plex Sans;
  color: #211E1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin-top: 20px;
  overflow: auto;
  overflow-x: hidden;
  /* height: 70%; */
  width: 100%;
  /* bottom: 0px; */
}

.containerSelfieLetter .cardSendVideo {
  display: flex;
  position: relative;
  width: 80vw;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  /* border: 1px solid #FFBEC2; */
  border-radius: 10px;
  background-color: #D8F6F5;
}

.containerSelfieLetter .cardSendVideo .containerText {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.containerSelfieLetter .cardSendVideo .containerText .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  font-family: IBM Plex Sans;
}

.containerSelfieLetter .cardSendVideo .containerText .subtitle1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  font-family: IBM Plex Sans;
}


.containerSelfieLetter .cardSendVideo .containerText .subtitle2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  font-family: IBM Plex Sans;
}

.containerSelfieLetter .cardSendVideo .containerText .buttonSend {
  width: Fixed (129px)px;
  height: Hug (38px)px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 80px;
  border: 0.8px solid #0AC6BD;
  cursor: pointer;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: #0AC6BD;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(
    to right,
    #0AC6BD 0%,
    #0AC6BD var(--progress),
    transparent var(--progress)
  ); */
  background-color: #FFFFFF;
  transition: background 0.3s ease;
}

.containerSelfieLetter .cardSendVideo img {
  position: absolute;
  right: 0px;
  top: -10px;
}

.containerSelfieLetter .titleContemt {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: center;
  color: #211E1F;
  margin-bottom: 15px;
}

.containerSelfieLetter .containerCorousel {
  position: relative;
  width: 90vw;
  align-self: center;
  overflow: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerSelfieLetter .containerCorousel::-webkit-scrollbar {
  display: none;
}

.containerSelfieLetter .corousel {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

@keyframes moverParaDireita {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}


.containerSelfieLetter .buttonSelfie {
  width: 80vw;
  height: Hug (38px)px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 100px;
  background-color: #ffffff;
  border: 1px solid #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #EC5569;
  cursor: pointer;
  /* margin-bottom: 20px; */
  margin-top: 20px;
  font-family: IBM Plex Sans;
  /* background: linear-gradient(
    to right,
    #EC5569 0%,
    #EC5569 var(--progress),
    transparent var(--progress)
  ); */
  background-color: #FFFFFF;
  transition: background 0.3s ease;
}

.containerSelfieLetter .buttonRemove {
  width: 80vw;
  height: Hug (38px)px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 100px;
  border: 1px;
  background-color: #FFFFFF;
  border: 1px solid #EC5569;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EC5569;
  cursor: pointer;
  margin-top: 20px;
}

.containerLoader {
  width: 90vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTextNew {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.containerTextNew .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  font-family: IBM Plex Sans;
}

.containerTextNew .subtitle1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  font-family: IBM Plex Sans;
}



@media only screen and (min-width: 800px) {
  .containerSelfieLetter .cardSendVideo {
    width: calc(90% - 40px) ;
  }

  .containerSelfieLetter .containerCorousel {
    position: relative;
    width: calc(90%);
    align-self: center;
    overflow: scroll;
    overflow-y:auto;
    -ms-overflow-style:auto;
    scrollbar-width:auto;

  }
  
  .containerSelfieLetter .containerCorousel::-webkit-scrollbar {
    display:flex;
  }

  .containerSelfieLetter .buttonSelfie {
    width: calc(80%);
    margin-bottom: 50px;
  }
  
  .containerSelfieLetter .buttonRemove {
    width: 330px;
    margin: auto;
    margin-top: 20px;
  }
  .containerLoader {
    width: calc(100%);
  }

  .webSelfie {
    width: calc(100% ) !important;
  }

  .containerTextNew {
    width: 330px !important;
  }

  .containerSelfieLetter .cardCompleteProfile {
    width: 330px !important;
  }
  
}