.sidebarFilter {
  width: 350px;
  border-radius: 20px;
  gap: 20px;
  border-width: 1px;
  padding: 36px;
  background: #F7F7F7;
  border: 1px solid var(--fake-white, #E3E3E3);
  display: flex;
  flex-direction: column;
}

.sidebarFilter .title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px;
}

.sidebarFilter .title img {
  width: 18px;
}

.sidebarFilter .subText {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  color: #091023;
}

.sidebarFilter .search-container {
  height: 46px;
  border-radius: 100px;
  gap: 6px;
  background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
  border: 1px solid #E3E3E3;
}

.sidebarFilter .search-container img {
  width: 30px;
  height: 30px;
}


.sidebarFilter .search-container .search-input {
  width: 85%;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #2D2D2D;
}

.sidebarFilter .search-container .search-input::placeholder {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #BFB7B8;
}

.sidebarFilter .inputClass {
  height: 46px;
  border-radius: 12px;
  gap: 6px;
  background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
  border: 1px solid #E3E3E3;
  padding: 0px 10px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #2D2D2D;
}

.sidebarFilter .search-container .inputClass::placeholder {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #BFB7B8;
}

.sidebarFilter select {
  height: 46px;
  border-radius: 12px;
  gap: 6px;
  background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
  border: 1px solid #E3E3E3;
  padding: 0px 10px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #2D2D2D;
}

.sidebarFilter .search-container select::placeholder {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #BFB7B8;
}

.sidebarFilter .filter-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebarFilter .filter-section .input,
.sidebarFilter .filter-section .salary-inputs {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.sidebarFilter .inputsContainer {
  display: flex;
  gap: 10px;
}

.sidebarFilter .check-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #091023;
}

.sidebarFilter .check-label input {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: #FFFFFFE1;
  border: 1px solid #ABABAB;
  appearance: none;
  /* Remove o estilo padrão */
  cursor: pointer;
  position: relative;
  margin: 0px;
}

.sidebarFilter .check-label input:checked {
  border: 1px solid #0AC6BD;
  background: #6CDDD7;
}

.sidebarFilter .check-label input:checked::before {
  content: "✔";
  /* Ícone de check */
  font-size: 12px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebarFilter .filter-section .radio-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sidebarFilter .radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 14px;
  color: #091023;
}

.sidebarFilter .radio-label input {
  width: 18px;
  height: 18px;
  border-radius: 40px;
  background: #FFFFFFE1;
  border: 1px solid #ABABAB;
  appearance: none;
  /* Remove o estilo padrão */
  cursor: pointer;
  position: relative;
  margin: 0px;
}

.sidebarFilter .radio-label input:checked {
  border: 1px solid #0AC6BD;
  background: #6CDDD7;
}

.sidebarFilter .radio-label input:checked::before {
  content: "✔";
  /* Ícone de check */
  font-size: 12px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filter-section .card {
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: 10px;
  height: 66px;
  padding: 5px 10px;
  background: var(--White, #FFFFFF);
  border: 1px solid var(--fake-white, #E3E3E3)
}

.filter-section .checked {
  background: #D8F6F5;
  border: 1px solid #0AC6BD;
}

.filter-section .card .conteinerNivel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.filter-section .card .conteinerNivel .text {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  color: #2D2D2D;
}

.sidebarFilter .search-button {
  width: 214px;
  height: 50px;
  border-radius: 100px;
  background: #EC5569;
  border: 1px solid #EC5569;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
  cursor: pointer;
}

.sidebarFilter .load-more-button {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  color: #EC5569;
  background-color: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.sidebarFilter .containerFIltersSelecteds {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sidebarFilter .cardFilterSelected {
  border-radius: 12px;
  padding-top: 6px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  gap: 5px;
  background: #D8F6F5;
  border: 1px solid #57BAB8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarFilter .cardFilterSelected img {
  width: 12px;
}


.sidebarFilter .cardFilterSelected .text {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 12px;
  color: #2D2D2D;
}

.sidebarFilter .remove-filter-btn {
  background-color: transparent;
  font-weight: 600;
  font-size: 18px;
  border: none;
  cursor: pointer;
  width: auto;
  height: auto;
}