/* SingleChatComponent.css */
.chat-system-container {
    position: fixed;
    bottom: 0px;
    right: 16px;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    font-family: IBM Plex Sans;
    z-index: 120000;
    align-items: flex-end;
    /* width: 500px; */
}

/* Contacts List - Expanded */
.contacts-list-container {
    min-width: 251px;
    height: 331px;
    border-width: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 20px 0px #0000001A;
}

.contacts-header {
    width: 100%;
    height: 70px;
    border-width: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #F7F7F7;
    border: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contacts-header .containerTitleHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contacts-header .containerTitleHeader img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}


.contacts-header .containerTitleHeader span {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 16px;
    color: #211E1F;
}

.dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
}

.contacts-scroll {
    height: 235px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    gap: 10px;
    padding: 10px 0px;
    /* Esconder a barra de rolagem no Chrome, Edge e Safari */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer e Edge */
}

.contacts-scroll::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari e Edge */
}

/* Contacts List - Collapsed */
.contacts-collapsed {
    width: 251px;
    border-width: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid #E3E3E3;
    background: #F7F7F7;
    cursor: pointer;
}


.contacts-collapsed-header {
    width: 100%;
    height: 70px;
    border-width: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #F7F7F7;
    border: 1px solid #E3E3E3;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contacts-collapsed-header .containerTitleHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contacts-collapsed-header .containerTitleHeader img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.contacts-collapsed-header .containerTitleHeader span {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 16px;
    color: #211E1F;
}

/* Contact Item */
.chat-system-container .active {
    border: 1px solid #0AC6BD;
    background: #D8F6F5;
}

.contact-item {
    width: 211px;
    height: 65px;
    border-radius: 12px;
    gap: 8px;
    border-width: 1px;
    display: flex;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.contacts-scroll .contact-item .containerIconProfile {
    position: relative;
    max-height: 90%;
}

.contacts-scroll .contact-item .containerIconProfile .statusON {
    width: 12px;
    height: 12px;
    background: #0AC6BD;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    bottom: 8px;
}

.contacts-scroll .contact-item .containerIconProfile .statusOff {
    width: 12px;
    height: 12px;
    background: #EC5569;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    bottom: 8px;
}


.contacts-scroll .contact-item .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    padding: 10px;
}

.contacts-scroll .contact-item .contact-info {
    flex: 1;
    overflow: hidden;
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 10px;
    color: #575757;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.contacts-scroll .contact-item .contact-name {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.contacts-scroll .contact-item .contact-status {
    font-size: 12px;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contacts-scroll .contact-item .contact-time {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 8px;
    color: #2D2D2D;
    white-space: nowrap;
}

/* Individual Chat Window */
.chat-window {
    width: 210px;
    height: 261px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: fixed;
    right: 308px;
    bottom: 0px;
}

/* Chat Header */
.chat-header {
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    height: 50px;
    gap: 10px;
    padding: 0px 10px;
}

.chat-header img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.chat-header .user-info .containerIconProfile {
    position: relative;
    width: 30px;
    height: 30px;
}

.chat-header .user-info .containerIconProfile .statusON {
    width: 10px;
    height: 10px;
    background: #0AC6BD;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.chat-header .user-info .containerIconProfile .statusOff {
    width: 10px;
    height: 10px;
    background: #EC5569;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.user-info {
    flex: 1;
    overflow: hidden;
}

.user-name {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.close-button .iconClose {
    width: 10px;
    height: 10px;
}

/* Messages Area */
.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    background-image: url(../../assets/bg\ \(1\).svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    /* Esconder a barra de rolagem no Chrome, Edge e Safari */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer e Edge */
}

.chat-messages::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari e Edge */
}

.message {
    max-width: 80%;
    margin-bottom: 12px;
    border-radius: 16px;
    padding: 8px 12px;
    position: relative;
}

.user-message {
    align-self: flex-end;
    background: #D8F6F5;
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 11px;
    color: #2D2D2D;
}



.contact-message {
    align-self: flex-start;
    font-family: IBM Plex Sans;
    font-weight: 300;
    background-color: #ffffff;
    font-size: 11px;
    color: #2D2D2D;
}

.message-content {
    display: flex;
    flex-direction: column;
}

.message-time {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 6px;
    color: #2D2D2D;
    align-self: flex-end;
}

.message-text {
    font-size: 13px;
    line-height: 1.4;
}

/* Chat Input */
.chat-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    background: #FFFFFF;

}

.chat-input input {
    width: 139px;
    /* height: 36px; */
    border-radius: 100px;
    border-width: 1px;
    gap: 10px;
    background: var(--White, #FFFFFF);
    border: 1px solid var(--fake-white, #E3E3E3);
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 12px;
    color: #818181;
    padding: 8px;
}

.chat-input input:focus {
    outline: none;
    border: none;
    border: 1px solid var(--fake-white, #E3E3E3);
}


.send-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.send-button img {
    width: 24.110721588134766px;
    height: 21.793903350830078px;
}