.topBarCompanies {
  position: fixed;
  top: 28px;
  left: 170px;
  right: 30px;
  z-index: 100000;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}