.boxSelfieLetter {
    display: flex;
    gap: 30px;
}

.boxSelfieLetter .box1,
.boxSelfieLetter .box2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.boxSelfieLetter .box1 div,
.boxSelfieLetter .box2 div {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 20px;
    color: #211E1F;
}

.boxSelfieLetter .box1 span,
.boxSelfieLetter .box1 li {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 14px;
    line-height: 18.2px;
    color: #000000;
}