.containerScreen1 {
  font-family: IBM Plex Sans;
  color: #211E1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin-top: 30px;
  width: 100%;
}

.containerScreen1 .cardSendVideo {
  display: flex;
  position: relative;
  margin: 0px 25px;
  height: 100px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  /* border: 1px solid #57BAB8; */
  border-radius: 10px;
  background: #D8F6F5;
}

.containerScreen1 .cardSendVideo .containerText {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.containerScreen1 .cardSendVideo .containerText .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.containerScreen1 .cardSendVideo .containerText .subtitle {
  font-size: 16px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #211E1F;
}

.containerScreen1 .cardSendVideo .containerText .buttonSend {
  width: Fixed (129px)px;
  height: Hug (38px)px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 100px;
  background-color: #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}

.containerScreen1 .cardSendVideo img {
  position: absolute;
  right: 0px;
  top: -15px;
}

.containerScreen1 .cardCenter {
  width: 80vw;
  height: 305px;
  padding: 18px;
  gap: 28px;
  border-radius: 10px;
  background: #F7F7F7;
  /* border: 1px solid #E3E3E3; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerScreen1 .cardCenter .text {
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: center;
  color: #211E1F;
}

@media only screen and (min-width: 800px) {
  .containerScreen1 .cardCenter {
    width: 330px;
  }


.containerScreen1 .cardCompleteProfile {
  width: 330px !important;
}
}