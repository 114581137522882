.gridKnowledge {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.gridKnowledge .cardKnowledgeProfile {
  padding: 20px;
  border-radius: 12px;
  gap: 10px;
  background: var(--White, #FFFFFF);
  border: 1px solid var(--fake-white, #E3E3E3);
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.gridKnowledge .cardKnowledgeProfile .containerNivelProfile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gridKnowledge .cardKnowledgeProfile .containerNivelProfile .text {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  color: #2D2D2D;
}