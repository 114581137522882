@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@keyframes loadingAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  animation: loadingAnimation 1s infinite;
}

.containerCulturalFIt {
  font-family: Barlow Semi Condensed;
  color: #211E1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  padding: '10px 15px'
}

.containerCulturalFIt .buttonSelfie {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #EC5569;
  cursor: pointer;


  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #EC5569;
  background: #FFFFFF;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: left;
  margin-bottom: 20px;
}

.containerCulturalFIt .buttonRemove {
  width: 80vw;
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 100px;
  background: #FFFFFF;
  border: 1px solid #EC5569;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #EC5569;
  line-height: 16px;
}

@media only screen and (min-width: 800px) {
  .containerCulturalFIt .buttonSelfie {
    width: 80%;
  }
  .containerCulturalFIt .buttonRemove {
    width: calc(100% - 32px);
  }
  
}