/* Individual Chat Window */
.chat-window-all {
    z-index: 999999;
    width: 210px;
    height: 261px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: fixed;
    right: 308px;
    bottom: 10px;
}

/* Chat Header */
.chat-header {
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    height: 50px;
    gap: 10px;
    padding: 0px 10px;
}

.chat-header img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.chat-header .user-info .containerIconProfile {
    position: relative;
    width: 30px;
    height: 30px;
}

.chat-header .user-info .containerIconProfile .statusON {
    width: 10px;
    height: 10px;
    background: #0AC6BD;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.chat-header .user-info .containerIconProfile .statusOff {
    width: 10px;
    height: 10px;
    background: #EC5569;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.user-info {
    flex: 1;
    overflow: hidden;
}

.user-name {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.close-button .iconClose {
    width: 10px;
    height: 10px;
}

/* Messages Area */
.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    background-image: url(../../assets/bg\ \(1\).svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    /* Esconder a barra de rolagem no Chrome, Edge e Safari */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer e Edge */
}

.chat-messages::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari e Edge */
}

.message {
    max-width: 80%;
    margin-bottom: 12px;
    border-radius: 16px;
    padding: 8px 12px;
    position: relative;
}

.user-message {
    align-self: flex-end;
    background: #D8F6F5;
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 11px;
    color: #2D2D2D;
}



.contact-message {
    align-self: flex-start;
    font-family: IBM Plex Sans;
    font-weight: 300;
    background-color: #ffffff;
    font-size: 11px;
    color: #2D2D2D;
}

.message-content {
    display: flex;
    flex-direction: column;
}

.message-time {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 6px;
    color: #2D2D2D;
    align-self: flex-end;
}

.message-text {
    font-size: 13px;
    line-height: 1.4;
}

/* Chat Input */
.chat-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    background: #FFFFFF;

}

.chat-input input {
    width: 139px;
    /* height: 36px; */
    border-radius: 100px;
    border-width: 1px;
    gap: 10px;
    background: var(--White, #FFFFFF);
    border: 1px solid var(--fake-white, #E3E3E3);
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 12px;
    color: #818181;
    padding: 8px;
}

.chat-input input:focus {
    outline: none;
    border: none;
    border: 1px solid var(--fake-white, #E3E3E3);
}


.send-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.send-button img {
    width: 24.110721588134766px;
    height: 21.793903350830078px;
}