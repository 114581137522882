.containerLayoutSpace {
  height: 100vh;
  width: 100vw;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerLayoutSpace .containerBackground {
  position: fixed;
  width: 100%;
  height: 65vh;
  object-fit: cover;
  object-position: bottom;
  bottom: 0;
}

.containerGeralItens {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  order: 1;
}

.containerLayoutSpace .logoImg {
  /* position: absolute; */
  
  width: 150px;
  /* top: 30px; */
}