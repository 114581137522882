.backgroundWeb {
  background-image: url(../../../assets/Home\ -\ Desktop.svg);
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.contentContainerWebCompanies {
  width: 960px;
  height: 839px;
  border-radius: 24px;
  background-color: #FFFFFF;
  /* border: 10px solid #2D2D2D; */
  border-image-source: linear-gradient(0deg, #2D2D2D, #2D2D2D),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  box-shadow: 0px 4px 20px 0px #2F2F2F33;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contentContainerWebCompanies .backGrounDividWeb {
  width: 900px;
  height: 57.61px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--fake-white, #E3E3E3);
  margin-top: 10px;
}

.contentContainerWebCompanies .logoWeb {
  width: 90px;
}

.contentContainerWebCompanies .boxLoginContainerCOmpanies {
  width: 950px;
  height: 566px;
  gap: 20px;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(../../../assets//Frame\ 427320198.svg);
  background-size: cover;
  position: relative;
}

.contentContainerWebCompanies .boxLoginContainerCOmpanies .title {
  font-family: Barlow Semi Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  color: #FFFFFF;
  width: 75%;
}

.contentContainerWebCompanies .boxLoginContainerCOmpanies .subtitle {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.2px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
  width: 90%;
}

.contentContainerWebCompanies .boxLoginContainerCOmpanies .contianerButtonsLoginWeb {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  justify-content: center;
}

.contentContainerWebCompanies .boxLoginContainerCOmpanies .buttonLoginWeb {
  width: 214px;
  height: 40px;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background: #EC5569;
  border: 1px solid #EC5569
}


.contentContainerWebCompanies .boxLoginContainerCOmpanies .style2 {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}


.contentContainerWebCompanies .imageLOgin {
  width: 214px;
  /* height: 245px; */
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footerLOginWeb {
  display: flex;
  /* width: 80vw; */
  height: 57px;
  justify-content: center;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  color: #2D2D2D;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.footerLOginWeb img {
  width: 14px;
  height: 11.99px;
}

.footerAViso {
  bottom: 15px;
  width: 1000px;
  height: 151px;
  border-radius: 20px;
  border: 1px solid #262626;
  background: #262626;
  display: flex;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px;
}

.footerAViso .astroIcon {
  width: 99px;
  height: 171px;
  position: absolute;
  top: -40px;
  margin-left: 25px;
}

.footerAViso .textAViso {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
}

.footerAViso .ButtonDonwload {
  width: 305px !important;
  padding: 9px 24px 11px 24px;
  border-radius: 100px;
  background: #EC5569;
  border: 1px solid #EC5569;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  cursor: pointer;
}

.backgroundWeb .containerInput {
  /* width: 80vw; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.backgroundWeb .containerInput .text {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 5px;
  text-align: left;
  color: #FFFFFF;
}

.backgroundWeb .containerInput .inputLogin {
  height: 35px;
  border-radius: 5px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  outline: none;
  /* padding: 5px; */
}

.backgroundWeb .contianerButtonsLogin {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  align-items: center;
}

.backgroundWeb .buttonLogin {
  height: 25px;
  border-radius: 100px;
  padding: 9px 24px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
}

.backgroundWeb .style1 {
  border: 1px solid #EC5569;
  background: #EC5569;
}

.backgroundWeb .style2 {
  height: 43px;
  gap: 8px;
  border-radius: 80px;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  color: #FFFFFF;
  border: 0.8px solid var(--White, #FFFFFF);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.backgroundWeb .containerINputSend {
  width: 300px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  /* Color negro con 50% de transparencia */
  border-radius: 20px;
}

.backgroundWeb .termosBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.backgroundWeb .termosBox p {
  width: 99%;
}

.backgroundWeb .termosBox p,
.backgroundWeb .termosBox p a {
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.backgroundWeb .inputLoginWeb {
  width: Fill (290px)px;
  height: Hug (50px)px;
  padding: 14px 20px 15px 20px;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.containerScaleCompaniesLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

@media only screen and (max-height: 1200px) {
  .containerScaleCompaniesLogin {
    transform: scale(1);
    transform-origin: center;
  }
}

@media only screen and (max-height: 863px) {
  .containerScaleCompaniesLogin {
    transform: scale(0.8);
    transform-origin: center;
  }
}

@media only screen and (max-height: 772px) {
  .containerScaleCompaniesLogin {
    transform: scale(0.7);
    transform-origin: center;
  }
}

@media only screen and (max-height: 678px) {
  .containerScaleCompaniesLogin {
    transform: scale(0.6);
    transform-origin: center;
  }
}

@media only screen and (max-height: 580px) {
  .containerScaleCompaniesLogin {
    transform: scale(0.5);
    transform-origin: center;
  }
}

@media only screen and (max-height: 489px) {
  .containerScaleCompaniesLogin {
    transform: scale(0.4);
    transform-origin: center;
  }
}

@media only screen and (max-width: 649px) {
  .backgroundWeb {
    display: none;
  }
}