/* AstronautAnimation.css */
.space-container {
  position: fixed;
  display: flex;
  align-items: flex-end; /* Inicia o astronauta na parte inferior */
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Oculta o excesso da animação */
  background-image: url(../../assets/Frame\ 427320198.svg); /* Adicione sua imagem de fundo */
  background-size: cover; /* Ajusta a imagem de fundo para cobrir a tela */
  background-color: #000; /* Cor de fundo do espaço */
  animation: pullScreen 1s ease-in forwards; /* Animação para puxar a tela */
  animation-delay: 1.2s;
  z-index: 3000;
}

.astronaut {
  width: 160px; /* Ajuste conforme necessário */
  animation: float 1.5s ease-in forwards; /* Animação flutuante */
}

@keyframes float {
  0% {
    transform: translate(-30vw, -30vh); /* Começa mais próximo do topo e à esquerda */
  }
  100% {
    transform: translate(100vw, -100vh); /* Move para a direita e para baixo */
  }
}


@keyframes pullScreen {
  0% {
    transform: translateY(0); /* Começa na posição original */
  }
  100% {
    transform: translateY(-100vh); /* Move a tela para cima completamente */
  }
}

@keyframes twinkling {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
