.containerBoxCenterCompanies {
  position: fixed;
  top: 100px;
  left: 170px;
  right: 30px;
  bottom: 50px;
  z-index: 100000;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px 0px #00000014;
  border-radius: 20px;
  border-bottom-width: 5px;
  padding: 40px;
  overflow: auto;
  /* Garante o scroll interno */
}

/* Ocultando a barra de rolagem */
.containerBoxCenterCompanies::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}