.chat-container-inbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  position: fixed;
  bottom: 0px;
  top: 75px;
  width: 100%;
}

.chat-container-inbox .messagesInbox {
  flex-grow: 1;
  /* Faz a área de mensagens ocupar o espaço disponível */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-image: url(../../assets/bg\ \(1\).svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}

.chat-container-inbox .messagesInbox .messageInbox {
  padding: 20px;
  border-radius: 12px;
  font-family: IBM Plex Sans;
  font-weight: 300;
  color: #2D2D2D;
}

.chat-container-inbox .messagesInbox .messageInbox.bot {
  background: var(--White, #FFFFFF);
  margin-right: 50px;
}

.chat-container-inbox .messagesInbox .messageInbox.user {
  background: #D8F6F5;
  margin-left: 50px;
}

.chat-container-inbox .input-containers {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background: #fff;
  /* flex-shrink: 0; */
}

.chat-container-inbox .input-containers input {
  border: none;
  outline: none;
  height: 50px !important;
  width: 100%;
  border: 1px solid var(--fake-white, #E3E3E3);
  background: var(--White, #FFFFFF);
  border-radius: 100px;
  padding: 0px 12px;
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 16px;
  color: #818181;
}

.chat-container-inbox .input-containers button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.chat-container-inbox .input-containers button img {
  width: 40px;
  height: 36.156368255615234px;
}

.chat-container-inbox .headerBackINbox {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 16px;
  color: #818181;
}

.chat-container-inbox .headerBackINbox img {
  width: 28px;
  height: 28px;
}