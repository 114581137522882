.tooltip {
  z-index: 1;
  white-space: nowrap;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  background-color: #757575;
  border-radius: 2px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  -webkit-transform: translateY(-100%) scale(0);
  -moz-transform: translateY(-100%) scale(0);
  -ms-transform: translateY(-100%) scale(0);
  transform: translateY(-100%) scale(0);
  top: -10px;
  transform-origin: bottom center;
}

.talentCard {
  padding: 22px;
  gap: 10px;
  border-radius: 10px 10px 0px 10px;
  background: #D8F6F5;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  color: #211E1F;
  align-self: flex-end;
  margin-bottom: 20px;
  width: 70vw;
  white-space: normal;
  word-wrap: break-word;
}

.talentCardSelfie {}

.systemCard {
  padding: 22px;
  border-radius: 10px 10px 10px 0px;
  background: #FFFFFF;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  color: #211E1F;
  align-self: flex-start;
  margin-bottom: 20px;
  width: 70vw;
}

.containerCardMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.inputSTyleSend,
.inputSTyleSendSelect {
  /* padding: 16px; */
  padding-left: 16px !important;
  padding-right: 16px !important;
  gap: 10px !important;
  border-radius: 100px !important;
  background: #FFFFFF !important;
  border: 1px solid #ABABAB !important;
  font-family: IBM Plex Sans !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 20.8px !important;
  color: #BFB7B8 !important;
  height: 50px !important;
}

.inputCustom {

  background: #FFFFFF !important;
  border: none !important;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 20.8px !important;
  color: #BFB7B8 !important;
  height: 42px !important;
  outline: none !important; /* Remove o contorno ao focar no campo */
  box-shadow: none !important; /* Remove qualquer sombra adicional */
  align-self: center;
}

.optionCustom {
  padding: 10px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #2D2D2D !important;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.optionCustom:hover {
  background: #f0f0f0;
}


.inputSTyleSendSelect {
  padding-right: 30px;
  /* Espaço para a seta personalizada */
  -webkit-appearance: none;
  /* Remove seta padrão no Safari e Chrome */
  -moz-appearance: none;
  /* Remove seta padrão no Firefox */
  appearance: none;
  /* Remove seta padrão no IE */
}

.inputSTyleSendSelectAuto {
  padding-left: 16px !important;
  padding-right: 16px !important;
  gap: 10px !important;
  border-radius: 100px !important;
  background: #FFFFFF !important;
  border: 1px solid #ABABAB !important;
  font-family: IBM Plex Sans !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 20.8px !important;
  color: #BFB7B8 !important;
  height: 50px !important;
}


.selectContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selectContainer::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%, -50%);
  width: 12px;
  /* Ajuste a largura desejada */
  height: 12px;
  /* Ajuste a altura desejada */
  background-image: url(../../assets/Vector\ \(18\).png);
  background-size: contain;
  /* Faz a imagem caber no tamanho definido */
  background-repeat: no-repeat;
  pointer-events: none;
}

.containerInputsChat input {
  border: 1px solid #ABABAB;
  background: #FFFFFF;
}


.containerInputsChat:focus {
  outline: none !important;
}

.containerInputsChat input:focus {
  outline: none !important;
}

.containerInputsChat select {
  border: 1px solid #ABABAB;
  background: #FFFFFF;
}

.containerInputsChat select:focus {
  outline: none !important;
}

.containerInputsChat .inputListSelect {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #211E1F;

}

.cardAreas {
  display: flex;
  align-items: center;
  padding: 18px 10px 18px 10px;
  gap: 6px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #211E1F;
  width: 48%;
}

/* .cardAreas:hover {
  background: #2D2D2D;
  color: #ffffff;
} */

.cardSlideSend {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 8px;
  border-radius: 10px;
  background: #FFFFFF;
}

.cardSlideSend2 {
  background: transparent;
}

.textCardSend {
  font-family: Barlow Semi Condensed;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #2D2D2D;

}

.containerChatSignUp {
  overflow: hidden;
  top: 0px;
  bottom: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  right: 0px;
  background-color: #fff;
}

.containerChatSignUp .containerHeaderChatSignUp {
  height: 100px;
  border-bottom: 1px solid #D9D9D966;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerChatSignUp .containerHeaderChatSignUp img {
  width: 95px;
}

.containerChatSignUp .containerBoxMessagens {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  background-color: #D9D9D940;
  background-image: url(../../assets/Clip\ path\ group.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  /* margin: 5px 0px; */
}

.containerChatSignUp .containerInputsChat {
  /* position: fixed; */
  bottom: 0px;
  padding: 0px 20px;
  background-color: #fff;
  height: 130px;
  display: flex;
  align-items: center;
}

.containerChatSignUp .containerInputsChat .inputSTyleSend {
  width: 100% !important;
}

.containerChatSignUp .containerInputsChat .inputSTyleSendSelect {
  width: 100% !important;
}

.containerChatSignUp .containerInputsChat .ddatWidht {
  width: 90vw !important;
}


.containerChatSignUp .containerInputsChat .inputSTyleSend::placeholder,
.containerChatSignUp .containerInputsChat .inputSTyleSendSelect::placeholder {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #818181;
}

.containerChatSignUp .containerInputsChat .inputSTyleSend,
.containerChatSignUp .containerInputsChat .inputSTyleSendSelect {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #2D2D2D !important;
}

@keyframes typing {
  0% {
    content: '';
  }

  20% {
    content: '.';
  }

  40% {
    content: '..';
  }

  60% {
    content: '...';
  }

  80% {
    content: '....';
  }

  100% {
    content: '.....';
  }
}

.containerCardSystems {
  border-radius: 10px 10px 10px 0px;
  background: #FFFFFF;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #211E1F;
  align-self: flex-start;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.typing {
  min-height: 30px;
  display: flex;
  justify-content: center;
}

.typing::after {
  content: '';
  animation: typing 1s steps(6, end) infinite;
}

.buttoninsert2 {
  border: none;
  background: #EC5569;
  border: 1px solid #EC5569;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
  /* width: 100%; */
  flex: 1;
}

.buttoninsert3 {
  border: none;
  background: #0AC6BD;
  border: 1px solid #0AC6BD;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
  /* width: 100%; */
  flex: 1;
}

.buttoninsert {
  border: none;
  background: #0AC6BD;
  border: 1px solid #0AC6BD;
  border-radius: 100px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
  /* width: 100%; */
  flex: 1;
}

.iconTecnology {
  width: 100%;
}

.autocomplete-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white; /* adicione se necessário */
}

@media only screen and (min-width: 800px) {

  .containerChatSignUp .containerBoxMessagens {
    padding: 10px;
  }

  .containerBoxChatWeb {
    width: 100vw;
    height: 100vh;
    background: #FFFFFF;
    position: relative;
  }

  .backgroundCHat {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 60vh;
    background-image: url(../../assets//Frame\ 427320264.svg);
  }

  .containerChatSignUp {
   position: relative;
   width: 424px;
        height: 80vh;
    /* padding: 20px; */
   border-radius: 10px;
   background: #FFFFFF;
   border: 1px solid #ABABAB;
       position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* align-items: center; */

  }

  .containerChatSignUp .containerBoxMessagens {
    /* height: 100%;
width: 100% !important; */
    /* margin: 5px 0px; */
  }

  .talentCard {
    width: 80%;

  }
  .systemCard {
    width: 80%;
  }
  .cardAreas {
    width: 48%;
  }
}