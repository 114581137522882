.textProfile {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #FFFFFF;
}

.textProfileSUb2 {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  color: #2D2D2D;
  margin-top: 10px;
  margin-bottom: 5px;
}

.textProfileSUb {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  color: #FFFFFF;
}

.buttonCardHome {
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 80px;
  border: 0.8px solid #0AC6BD;
  background:#FFFFFF;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: #0AC6BD;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textTitle {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: left;
  color: #2D2D2D;
  margin-bottom: 10px;
}


.containerHabilits {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 80vw;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 6px;
  border-radius: 10px;
  background: #F7F7F7;

}

.titleCard {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  color: #211E1F;
}

.slideHOme {
  width: 80vw;
}

.KnowledgeCard {
  border-radius: 10px;
  background: #FFFFFF;
  width: 145px !important;
  margin: 0px 6px;
  padding: 10px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerCardAstr {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.containerCardAstr .cardCompleteProfile {
  width: 80vw;
  /* height: 180px; */
  padding: 20px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background: #2D2D2D;
  display: flex;
  font-family: IBM Plex Sans;
}

.containerCardAstr .cardCompleteProfile .cardLeft .textProfile {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
}

.containerCardAstr .cardCompleteProfile .cardLeft .textProfileSUb {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  color: #FFFFFF;
  margin-left: 10px;
}

.containerCardAstr .cardCompleteProfile .cardLeft .button {
  width: 100%;
  height: 32px;
  border-radius: 80px;
  background: #FFFFFF;
  border: 0.8px solid #2D2D2D;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 500;
  color: #2D2D2D;
  display: flex;
  align-items: center;
  justify-content: center;
}


.containerCardAstr .cardCompleteProfile .cardLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.containerCardAstr .cardCompleteProfile .cardRigth {
  flex: 0.6;
}

.products {
  background-color:#999999;
  padding:20px;
}
.product {
  background-color:#111111;
  padding:20px;
  color:#fff;
  margin-bottom:20px;
  height:400px;
}
.fadein {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px);
}

.fadein.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.anamitionCard {
  animation: twinkling 0.8s ease-in forwards; 
}

.cardAVatar {
  background-image: url(../../assets//Group\ 1\ \(1\).png);
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardAVatar img {
  width: 50%;
}


@keyframes twinkling {
  from {
    opacity: 0;
    transition: all 1s;
    transform: translate(0, 100px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media only screen and (min-width: 800px) {
  .containerCardAstr .cardCompleteProfile {
    width: calc(100% - 36px) !important;
  }

  
  .fadein {
    opacity: 1;
    transition: none;
    transform: none;
  }
  
  .fadein.visible {
    opacity: 1;
    transform: none;
  }
  
  .anamitionCard {
    animation: none 
  }

  .containerHabilits {
    width: 330px !important;
  }

  /* .containerHabilits img {
    left: 80% !important
  } */

  .slideHOme {
    width: 17vw;
  }
}
