.containerScreen2 {
  font-family: IBM Plex Sans;
  color: #211E1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 20px;
  top: 70px;
}

.containerScreen2 .cardCenter {
  width: 75vw;
  padding: 30px;
  gap: 18px;
  border-radius: 10px;
  background: #F7F7F7;
  /* border: 1px solid #E3E3E3; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.containerScreen2 .cardCenter .title {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #2D2D2D;
}

.containerScreen2 .cardCenter .subttile {
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
  color: #211E1F;

}

.containerScreen2 .cardCenter .containerQuestions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerScreen2 .cardCenter .containerQuestions div {
  font-family: IBM Plex Sans;
  font-size: 17px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.containerScreen2 .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10px;
  justify-content: end;
  /* margin-top: 10px; */
}

.containerScreen2 .fill {
  background-color: #BEFCF9;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  height: 100%;
}

.containerScreen2 .leads {
  background-color: #57BAB8;
  display: flex;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #102649;
  height: 100%;
}


.containerScreen2 .cardCenter2 {
  width: 75vw;
  padding: 30px;
  gap: 18px;
  border-radius: 10px;
  background: #D8F6F5;
  /* border: 1px solid #EC5569; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerScreen2 .cardCenter2 .title {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: #211E1F;
}

.containerScreen2 .cardCenter2 .subttile {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: center;
  color: #211E1F;
}

/* Oculta o radio button padrão */
.containerQuestions .radioContainer input[type="radio"] {
  opacity: 0;
  position: absolute;
}

/* Estiliza o círculo externo do radio */
.containerQuestions .radioContainer .radioPerso {
  content: '';
  display: inline-block;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0.8px solid #ABABAB;
  /* Espaçamento entre círculo e texto */
  position: relative;
  vertical-align: middle;
}

/* Círculo interno (a bola) quando o radio está selecionado */
.containerQuestions .radioPersoCheck {
  background-image: url(../../../assets//Frame\ 20\ \(7\).png);
  background-size: cover;
}

@media only screen and (min-width: 800px) {
  .containerScreen2 .cardCenter {
    width: 330px !important;
  }
  .containerScreen2 .cardCenter2 {
    width: 330px !important;
  }
}