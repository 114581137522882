.containerMenuCompanies {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 84.8px;
  height: 364px;
  border-radius: 40px;
  gap: 15px;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px 0px #00000014;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.itemLink {
  width: 54.8px;
  height: 54.8px;
  gap: 4px;
  border-radius: 27px;
  /* border: 0.4px solid #E3E3E3; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemLinkSelected {
  width: 54.8px;
  height: 54.8px;
  gap: 4px;
  border-radius: 27px;
  background: #FFE4E5;
  border: 1.5px solid #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemLink img,
.itemLinkSelected img {
  width: 30px;
}