.containerPainelHOme {
    /* padding: 20px; */
    margin-top: 10px;
    margin-left: 30px;
}

.containerPainelHOme .headerPainel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerPainelHOme .headerPainel .titlePanel {
    font-family: IBM Plex Sans;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
}

.containerPainelHOme .headerPainel .subtitlePanel {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
}

.titleContent {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.textContent {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 14px;
    color: #000000;
}

.containerPainelHOme .btton {
    width: 206px;
    height: 50px;
    border-radius: 100px;
    gap: 10px;
    border-width: 1px;
    background: #EC5569;
    border: 1px solid #EC5569;
    font-family: IBM Plex Sans;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.containerPainelHOme .contentContainer {
    margin-top: 60px;
    display: flex;
    gap: 44px;
    justify-content: space-between;
}

.containerPainelHOme .contentContainer .conainerLeft {
    /* background-color: #EC5569; */
    flex: 0.5;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.containerPainelHOme .contentContainer .conainerLeft .titlePanel {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
}

.containerPainelHOme .contentContainer .conainerLeft .subtitlePanel {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 6px;
}

.containerPainelHOme .contentContainer .conainerLeft .subtitlePanel img {
    width: 14.4375px;
    height: 18.37481689453125px;
}

.containerPainelHOme .contentContainer .conainerLeft img {
    width: 137px;
    height: 137px;
}

.containerPainelHOme .contentContainer .conainerRight {
    flex: 2;
}

.containerPainelHOme .contentContainer .conainerRight .containerFilters {
    display: flex;
    align-items: center;
    gap: 15px;
}

.containerPainelHOme .contentContainer .conainerRight .containerFilters .text {
    font-family: IBM Plex Sans;
    font-weight: 300;
    font-size: 14px;
    color: #211E1F;
}

.talent-list-container {
    display: flex;
    flex-direction: column;
    padding: 36px;
    gap: 20px;
    background: #F7F7F7;
    border: 1px solid var#E3E3E3;
    border-radius: 20px;
    min-height: 400px;
}

.talent-list-container .headerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.talent-list-container .headerTitle .titlePanel {
    font-family: IBM Plex Sans;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0%;
    color: #211E1F;

}

.search-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
    border: 1px solid var(--fake-white, #E3E3E3);
    height: 46px;
    padding: 0px 10px;
    gap: 6px;
    border-radius: 100px;
}

.search-container input {
    border: none;
    width: 250px;
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;
    color: #211E1F;
}

.search-container input::placeholder {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;
    color: #BFB7B8;
}

.search-container input:focus {
    outline: none;
}

.talent-list {
    display: flex;
    flex-wrap: wrap;
    gap: 21px;
    align-self: center;
}

.avatar {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.selfie-container {
    display: flex;
    background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
    border: 1px solid var(--fake-white, #E3E3E3);
    padding: 0px 10px;
    gap: 6px;
    border-radius: 100px;
    height: 46px;
    display: flex;
    align-items: center;
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-weight: 500;
    color: #211E1F;

}

.selfie-container svg {
    margin-right: 5px;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    /* max-width: 190px; */
}

.skill-button {
    border-radius: 12px;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
    gap: 5px;
    background: #D8F6F5;
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 11px;
    leading-trim: Cap height;
    line-height: 14.3px;
    letter-spacing: 0%;
    color: #2D2D2D;
}

.skill-button:hover {
    background: #a9eee8;
}

.profile-button {
    width: 100%;
    margin-top: 10px;
    color: #f50057;
    border-color: #f50057;
}

.favorite-badge {
    position: absolute;
    top: 10px;
    right: 10px;
}

.notification-icon {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
}

.containerPainationPainel {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}

.containerPainationPainel button {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 14px;
    color: #211E1F;
    border: none;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
}

.containerPainationPainel button img{
    height: 12px;
}


.containerPainationPainel .text {
    font-family: IBM Plex Sans;
    font-weight: 700;
    font-size: 14px;
    color: #EC5569;

}
