/* Estilos gerais do slider */
.slider-container {
    position: relative !important;
     width: 100vw; 
}

.containerSlide {
     width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative !important;
}


.containerSlide .card {
    width: 179px !important;
    height: 472.33px !important;
    border-radius: 12.8px;
    background: #F7F7F7;
    display: flex !important;
    align-items: center !important;
    flex-direction: column;
    position: relative !important;
    margin-top: 20px;
    margin-left: 6px;
    margin-right: 6px;
}

.containerSlide .card .index {
    background-color: #57BAB8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-family: IBM Plex Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    position: absolute !important;
    ;
    top: 0px !important;
    ;
    left: 50% !important;
    ;
    transform: translate(-50%, -50%) !important;
    ;
    z-index: 99999 !important;

}


.containerSlide .card .containerImage {
    width: 143px !important;
    height: 157px !important;
    gap: 10px;
    border-radius: 10px;
    background: #D8F6F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.containerSlide .card .containerImage img {
    /* width: 102px; */
    /* height: 109px; */
}

.containerSlide .card .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

.containerSlide .card .card-body .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: left;
    color: #211E1F;
    font-family: IBM Plex Sans;
    width: 143px;
}

.containerSlide .card .card-body .subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    text-align: left;
    font-family: IBM Plex Sans;
    width: 143px;
}

@media only screen and (min-width: 800px) {
    .slider-container {
         width: 100%; 
    }
  }