.containerPlans {
  width: 960px;
  height: 700px;
  padding: 38px;
  /* margin-top: 5%; */
  gap: 24px;
  border-radius: 20px;
  border: 1px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 2px 10px 0px #0000001A;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.containerPAymentMethod {

  overflow: auto;
  -ms-overflow-style: none;
  /* Esconde no IE e Edge */
  scrollbar-width: none;
  /* Esconde no Firefox */
}

/* Esconde a barra de rolagem no Chrome, Safari e Edge */
.containerPAymentMethod::-webkit-scrollbar {
  display: none;
}

.containerPlans .textBoxMessage {
  flex: 1;
  gap: 10px;
  border-radius: 10px;
  background: #F7F7F7;
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #211E1F;
  padding: 22px;
}

.containerPlans .containerMessage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerPlans .containerCardPlans {
  display: flex;
  gap: 15px;
}

.containerPlans .cardPLans {
  flex: 1;
  height: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px;
  background: #f7f7f7;
  border: 1px solid #FFFFFF;
}

.containerCardPlans .cardCentral {
  border: 2px solid #0AC6BD;
  position: relative;
}

.containerCardPlans .cardCentral .ofertPlan {
  width: 111;
  height: 18;
  border-radius: 100px;
  gap: 12px;
  padding-top: 2px;
  padding-right: 8px;
  padding-bottom: 2px;
  padding-left: 8px;
  background: #0AC6BD;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 11px;
  line-height: 14.3px;
  letter-spacing: 0%;
  color: #FFFFFF;
  position: absolute;
  top: -9px;
}

.containerPlans .cardPLans .title {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #091023;
}

.containerPlans .cardPLans .subTitle {
  font-family: Barlow Semi Condensed;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #091023;
}

.containerPlans .cardPLans .value {
  display: flex;
  align-items: center;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 36px;
  line-height: 46.8px;
  letter-spacing: 0%;
  color: #091023;
  gap: 6px;
}

.containerPlans .cardPLans .value .subTitle {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 0%;
  color: #091023;
}

.containerPlans .cardPLans button {
  /*width: Fixed (214.5px)px; */
  height: 43px;
  width: 100%;
  padding: 10px 16px 12px 16px;
  gap: 11px;
  border-radius: 110px;
  /* border: 1px 0px 0px 0px; */
  background: #EC5569;
  border: 1px solid #FFFFFF;
  font-family: Barlow Semi Condensed;
  font-size: 17.6px;
  font-weight: 700;
  line-height: 21.12px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.containerPlans .cardPLans .listItens {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.containerPlans .cardPLans .listItens .list .img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerPlans .cardPLans .listItens .list img {
  width: 16px;
  height: 16px;
}

.containerPlans .cardPLans .listItens .list {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  color: #091023;
  padding: 10px 0px;
  border-bottom: 1px solid #09102333;
}

.containerPlans .containerCardCheckout {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.containerPlans .containerCardCheckout .containerCreditCard {
  flex: 1;
  height: 500px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  border-radius: 20px;
  border: 1px;
  background: #f7f7f7;
  border: 1.1px solid #E3E3E3;
}

.containerPlans .containerCardCheckout .containerCreditCard .card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.containerPlans .containerCardCheckout .containerCreditCard .containerInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.containerPlans .containerCardCheckout .containerCreditCard .containerInput .title {
  font-family: Barlow Semi Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #091023;
}

.containerPlans .containerCardCheckout .containerCreditCard .containerInput .error {
  font-family: Barlow Semi Condensed;
  font-size: 9px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #8f0202;
}

.containerPlans .containerCardCheckout .containerCreditCard .containerInput .inputCredCard {
  padding: 12px;
  border-radius: 5px;
  justify-content: space-between;
  border: none;
}

.containerPlans .containerCardCheckout .containerCreditCard button {
  /*width: Fixed (214.5px)px; */
  height: 47px;
  padding: 8px 17.6px 13.2px 17.6px;
  gap: 11px;
  border-radius: 110px;
  border: 1px 0px 0px 0px;
  background: #EC5569;
  border: 1px solid #FFFFFF;
  font-family: Barlow Semi Condensed;
  font-size: 17.6px;
  font-weight: 700;
  line-height: 21.12px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 10px;
}

.period-selector {
  display: inline-flex;
  width: 354px;
  height: 61px;
  border-radius: 100px;
  justify-content: space-between;
  padding: 8px;
  background: #D8F6F5;
  align-self: center;
}

.period-button {
  width: 110px;
  height: 45px;
  border-radius: 100px;
  gap: 10px;
  padding-top: 12px;
  padding-right: 18px;
  padding-bottom: 12px;
  padding-left: 18px;
  border: none;
  cursor: pointer;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  background-color: #D8F6F5;
}

.period-button:hover:not(.active) {
  /* background-color: #e5e7eb; */
  color: #2D2D2D;
}

.period-button.active {
  background: #3BD1CA;
  color: white;
}

.period-button:focus {
  outline: none;
}

.containerSegureText {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ABABAB33;
  padding-bottom: 20px;
}


.containerSegureText img {
  width: 75px;
  height: 75px;
}

.containerSegureText .containerText {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerSegureText .containerText .title {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  color: #2D2D2D;

}

.containerSegureText .containerText .subtitle {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: 0%;
  color: #2D2D2D;
}

.containerPlans .containerSucess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.containerPlans .containerSucess img {
  width: 179px;
  height: 178px;
}

.containerPlans .containerSucess .text {
  width: 320px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: center;
  color: #091023;
}

.containerPlans .containerSucess .title {
  width: 320px;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 36px;
  line-height: 46.8px;
  letter-spacing: 0%;
  text-align: center;
  color: #091023;
}

.containerPlans .containerSucess button {
  width: 190px;
  height: 43px;
  border-radius: 100px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  background: #EC5569;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}