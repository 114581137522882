@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.talent-search-container {
  display: flex;
  min-height: 50vh;
  gap: 30px;
}

.titlePage {
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 36px;
  line-height: 46.8px;
  letter-spacing: 0%;
  color: #000000;
  margin-bottom: 25px;
  /* margin-top: 10px; */
}

.containerBoxCompanies .containerScaleCompanies .talent-search-container .contentContainer .headerTitle .title {
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 18px;
  line-height: 23.4px;
  letter-spacing: 0%;
  color: #211E1F;
}
