.footerMobile {
    display: flex;
    width: 95vw;
    height: 50px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    color: #2D2D2D;
    border-top: 1px solid #0000000D;
    margin: auto;
    background-color: #FFFFFF;
}

.footerMobileFixed {
    display: flex;
    width: 95vw;
    height: 50px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    color: #2D2D2D;
    border-top: 1px solid #0000000D;
    margin: auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #FFFFFF;
}

.footerPageMObileProfile {
    display: flex;
    width: 95vw;
    height: 50px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    color: #fff;
    background-color: #2D2D2D;
    border-top: 1px solid #FFFFFF0D;
    margin: auto;
    margin-top: 10px;
}

.footerMobile img,
.footerMobileFixed img,
.footerPageMObileProfile img {
    width: 14px;
    height: 11.99px;
}

.footerPage {
    display: none;
}

@media only screen and (min-width: 800px) {
    .containerBoxSpace {
        background-image: url(../../assets//Frame\ 427320198.svg);
        background-size: auto;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 20%;
    }

    .contentWEb {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* width: 50vw;
        height: 80vh; */
        width: 424px;
        height: 80vh;
        /* padding: 10px 15px; */
        background-color: #fff;
        border: 1px solid #ABABAB;
        z-index: 1000000;
        border-radius: 20px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #e45b6e transparent; /* Corrigido: a ordem deve ser thumb e track */
      }
      
      .contentWEb::-webkit-scrollbar {
        width: 6px;
      }
      
      .contentWEb::-webkit-scrollbar-thumb {
        background-color: #e45b6e;
        border-radius: 8px;
      }
      
      .contentWEb::-webkit-scrollbar-track {
        background-color: transparent;
      }
      

    .footerMobile,
    .footerMobileFixed,
    .footerPageMObileProfile {
        display: none;
    }

    .footerPage {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: IBM Plex Sans;
        font-size: 12px;
        font-weight: 300;
        line-height: 16.8px;
        color: #FFFFFF;
    }

    .footerPage img {
        width: 14px;
        height: 11.99px;
    }

}