.titlePageProfile {
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 36px;
  color: #000000;
  margin-bottom: 20px;
}

.containerBoxProfile {
  display: flex;
  gap: 30px;
}

.containerBoxProfile .containerInfoProfile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 350px;
}

.containerBoxProfile .containerInfoProfile .avatarProfile {
  width: 124px;
  height: 124px;
  background-color: #D9D9D9;
  border-radius: 50%;
  object-fit: fill;
}

.containerBoxProfile .containerInfoProfile .subTitlePorfile {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  color: #000000;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile {
  display: flex;
  gap: 5px;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile .box1 {
  /* width: 177;
height: 65; */
  border-radius: 12px;
  gap: 10px;
  border-width: 1px;
  padding: 10px;
  border: 1px solid #E3E3E3;
  display: flex;
  flex-direction: column;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile .box1 .containerInfoBox {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile .box1 .containerInfoBox .text {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 13px;
  color: #000000;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile .box2 {
  padding: 5px;
  display: flex;
  align-items: flex-start;
}

.containerBoxProfile .containerInfoProfile .cardInfoProfile .box2 .textState {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
}

.containerBoxProfile .buttonChatTalents {
  width: 207px;
  height: 38px;
  border-radius: 100px;
  border-width: 1px;
  background: #EC5569;
  border: 1px solid #EC5569;
  font-family: IBM Plex Sans;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}

.containerBoxProfile .tabs-container {
  flex: 1;
  margin: 0 auto;
  position: relative;
}

.containerBoxProfile .tabs-header {
  display: flex;
  gap: 10px;
  position: absolute;
  top: -11px;
  left: 30px;
}

.containerBoxProfile .tab-button {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  gap: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--White, #FFFFFF);
  border: 1px solid #E3E3E3;
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 16px;
  color: #211E1F;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.containerBoxProfile .tab-button.active {
  background: #F7F7F7;
  font-weight: 500;
  border-top: 1px solid #E3E3E3;
  border-left: 1px solid #E3E3E3;
  border-right: 1px solid #E3E3E3;
  border-bottom: transparent;
}

.containerBoxProfile .tab-content {
  flex: 1;
  min-height: 568px;
  border-radius: 20px;
  gap: 20px;
  padding: 40px;
  background: #F7F7F7;
  border: 1px solid var(--fake-white, #E3E3E3);
  display: flex;
  flex-direction: column;
  margin-top: 31px;
}

.containerBoxProfile .content-area {
  padding: 20px;
}

.containerBoxProfile .content-area h3 {
  margin-top: 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.containerBoxProfile .content-area p {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

.containerBoxProfile .boxNull {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.containerBoxProfile .boxNull .titleText {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #000000;
}

.containerBoxProfile .boxNull .subText {
  font-family: IBM Plex Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  color: #000000;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.headerContainer .breadcrumbContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerContainer .breadcrumb {
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
  color: #818181;
}

.headerContainer .breadcrumb .active {
  font-weight: 500;
  color: #000000;
}

.headerContainer .buttonBreadcrumb {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.headerContainer .buttonBreadcrumb img {
  width: 28px;
  height: 28px;
}
