.containerLogin .inputToken {
  width: calc(100%/6) !important;
  height: 40px !important;
  text-align: center;
  font-size: 24px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-family: Barlow Semi Condensed;
}

.backgroundWeb .inputToken {
  width: calc(100%/6) !important;
  height: 40px !important;
  text-align: center;
  font-size: 24px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
  font-family: Barlow Semi Condensed;
}