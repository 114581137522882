.containerPlayVideo {
    width: 85%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.containerPlayVideo .containerDetails {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.containerPlayVideo .containerDetails .containerHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.containerPlayVideo .containerDetails .containerHeader img {
    width: 28px;
    height: 28px;
}

.containerPlayVideo .containerDetails .containerHeader span {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #211E1F;
}

.containerPlayVideo .containerDetails .thumbIMage {
    width: 100%;
}

.containerPlayVideo .containerDetails .title {
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #211E1F;
}

.containerPlayVideo .containerDetails .subtitle {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.containerPlayVideo .containerDetails .containerSubtitle {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2D2D2D;
    display: flex;
    gap: 5px;
    align-items: center;
}

.containerPlayVideo .containerDetails .containerSubtitle img {
    width: 16px;
    height: 16px;
}

.containerPlayVideo .containerDetails .containerTeacher {
    width: Fill (330px)px;
    height: Hug (226px)px;
    padding: 20px;
    gap: 10px;
    border-radius: 20px;
    background: #F7F7F7;
    border: 1px solid #E3E3E3;
    display: flex;
    flex-direction: column;
}

.containerPlayVideo .containerDetails .containerTeacher .headers {
    display: flex;
    align-items: center;
    gap: 10px;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText span {
    width: Hug (74px)px;
    height: Hug (18px)px;
    padding: 2px 6px;
    gap: 3px;
    border-radius: 100px;
    background: var(--fake-white, #E3E3E3);
    font-family: IBM Plex Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 14.3px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText div {
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #211E1F;
}

.containerPlayVideo .containerDetails .containerTeacher .headers img {
    width: 50px;
    height: 50px;
}

.containerPlayVideo .containerDetails .containerTeacher .description {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText2 div,
.containerPlayVideo .containerDetails .containerTeacher .headers .containerText2 span {
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #211E1F;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText2 div {
    font-weight: 600;
}

.containerPlayVideo .containerDetails .containerTeacher .headers .containerText2 span {
    font-weight: 300;
}

.containerPlayVideo .containerDetails .card {
    display: flex;
    width: Fill (330px)px;
    height: Hug (130px)px;
    padding: 20px;
    gap: 20px;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    background-color: #F7F7F7;
    position: relative;
    align-items: center;
}

.containerPlayVideo .containerDetails .card_checked {
    border: 1px solid #0AC6BD;
    background-color: #D8F6F5;
  }

.containerPlayVideo .containerDetails .card .thumbnail {
    width: 130px;
    height: 90px;
    border-radius: 12px;
    background-color: #ABABAB;
    border: 1px solid #818181;
    position: relative;
    z-index: 2;
}

.containerPlayVideo .containerDetails .card .thumbnail_checked .thumb {
    opacity: 1
  }
  
  .containerPlayVideo .containerDetails .card .thumbnail_checked {
    background-color: #6CDDD7;
    border: 1px solid #0AC6BD;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.containerPlayVideo .containerDetails .card .thumbnail .iconPLay {
    display: none;
}

.containerPlayVideo .containerDetails .card .thumbnail .thumb {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}


.containerPlayVideo .containerDetails .card .containerText {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 6px;
    flex: 1;
}

.containerPlayVideo .containerDetails .card .containerText button {
    width: 100%;
    height: 38px;
    border-radius: 100px;
    gap: 10px;
    font-family: IBM Plex Sans;
font-weight: 700;
font-size: 14px;
color: #FFFFFF;

display: flex;
align-items: center;
justify-content: center !important;
}

.containerPlayVideo .containerDetails .card .containerText .title {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2D2D2D;
}

.containerPlayVideo .containerDetails .card .containerText .subtitle {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2D2D2D;
    display: flex;
    gap: 5px;
    align-items: center;
}

.containerPlayVideo .containerDetails .card .containerText .subtitle img {
    width: 16px;
    height: 16px;
}

.containerPlayVideo .containerDetails .textSugestao {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

}

.containerPlayVideo .containerDetails .containerButtons {
    display: flex;
    justify-content: center;
}

.containerPlayVideo .containerDetails .containerButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.containerPlayVideo .containerDetails .containerButtons button {
    height: 38px;
    border-radius: 100px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    align-self: center;
    flex: 1;
    background-color: #F7F7F7;
    border: 1px solid #E3E3E3;
    color: #2D2D2D;
}

.containerPlayVideo .containerDetails .checkVideo .cicle {
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 50%;
}

.containerPlayVideo .containerDetails .containerButtons .checked {
    background-color: #D8F6F5;
    border: 1px solid #0AC6BD
}

.containerPlayVideo .containerDetails .containerButtons .checked .imgCheck {
    width: 25px;
    height: 25px;
}

.containerPlayVideo .containerDetails .containerButtons .checked .cicle {
    display: none;
}