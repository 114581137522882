.titleKnowCard {

  font-family: IBM Plex Sans;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #211E1F;

}

.subTitleKnowCard {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  color: #211E1F;
  margin-top: 10px;
}

.textKnow {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  color: #211E1F;
  margin-bottom: 15px;
}

.titlePageSKils {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #211E1F;

}

.buttonSaveSKils {
  /* height: Hug (45px)px; */
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 100px;

  background: #FFFFFF;
  border: 1px solid #EC5569;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: left;
  color: #EC5569;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.containerButonsKnow {
  display: flex;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  height: 60px;
  width: 100%;
}

.containerBackgroundButons {
  background-color: #2D2D2D;
  width: 40px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
}

.containerButonsKnow .buttonSelectKnow {
  flex: 1;
  border: none;
  cursor: pointer;
  background: #2D2D2D;
  height: 60px;
  padding: 20px;
  gap: 10px;
  border-radius: 20px 20px 0px 0px;
  font-family: IBM Plex Sans;
  font-size: 15px;
  font-weight: 300;
  line-height: 19.5px;
  text-align: center;
  line-height: 15px;
  color: #ffffff;
  /* position: absolute; */
  z-index: 1;
}

.KnowledgeCardKNow {
  height: 73.14px;
  padding: 12px;
  gap: 6px;
  border-radius: 10px;
  border: 1px solid #E3E3E3;
  background: radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleModalKnow {
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.subTitleModalKnow {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  text-align: left;
  color: #FFFFFF;
}

/* ButtonStyle.css */
.message-button {
  width: 100px;
  height: 21px;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  background: #575757;
  border: 1px solid #575757;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
}

.message-button::before {
  content: '';                /* Sem conteúdo, apenas a forma da setinha */
  position: absolute;
  left: -4px;                /* Posição da setinha à esquerda do botão */
  top: 50%;
  transform: translateY(-50%); /* Alinha verticalmente ao centro */
  border-top: 4px solid transparent;  /* Lado superior da setinha */
  border-bottom: 4px solid transparent; /* Lado inferior da setinha */
  border-right: 4px solid #575757;  /* Cor da setinha, igual ao fundo do botão */
}

.message-button:hover {
  background-color: #616161;  /* Cor mais escura ao passar o mouse */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);  /* Aumenta a sombra ao passar o mouse */
}


.containerListItensKnow {
  border-radius: 10px;
  background: #F7F7F7;
  border: 1px solid #F7F7F7;
  margin: 0px;
}